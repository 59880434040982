import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Avatar } from "antd";
import Auth from '../../utils/Authorization';
import {
  LogoutOutlined,
  EditOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { connect } from 'react-redux';
import { getUserDetail } from './actions';
import { API_BASE_URL } from '../../configs/AppConfig';
import axios from 'axios';
import Authorization from 'utils/Authorization';
const menuItem = [
  {
    title: 'Profile',
    icon: EditOutlined,
    path: '/app/setting/edit-profile'
  }
  // {
  //   title: "Account Setting",
  //   icon: SettingOutlined,
  //   path: "/",
  // },
  // {
  //   title: "Billing",
  //   icon: ShopOutlined,
  //   path: "/",
  // },
  // {
  //   title: "Help Center",
  //   icon: QuestionCircleOutlined,
  //   path: "/",
  // },
];
 function UserDropdown(props) {
  const { profileInfo, dispatch, ...restProps } = props;

  useEffect(() => {
    dispatch(getUserDetail());
  }, []);
  const [profileDetails, setProfileDetails] = useState({});
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    const {
      response: { data = [] },
      status,
      isFetching,
      isError
    } = profileInfo;
    if (status >= 200 && status < 300) {
      if (!data.profileImage) {
        setImageUrl('/img/avatars/empty-image.png');
      }
      setProfileDetails(data);
    }
  }, [profileInfo]);

  const getImage = (value) => {
    const accessToken = Authorization.getAccessToken();
    const imgData = value || '';
    let imageUrl = '';
    if (imgData) {
      axios({
        method: 'get',
        url: `${API_BASE_URL}/media/download?mediaId=${imgData}`,
        responseType: 'arraybuffer',
        headers: {
          Authorization: accessToken
        }
      }).then(function (response) {
        imageUrl = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        );
        setImageUrl(`data:;base64,${imageUrl}`);
      });
    }
  };

  const { firstName = '', lastName = '', profileImage } = profileDetails;
  const profileImg = getImage(profileImage);
  const userMenu = ()=>{
    return (
      <div className="nav-profile nav-dropdown">
        <div className="nav-profile-header">
          <div className="d-flex">
            <Avatar size={35} src={imageUrl} style={{float:'right'}}/>
            <div className="pl-3">
              <h4 className="mb-0" >
                {firstName ? `${firstName} ${lastName}` : '-'}
              </h4>
              <span className="text-muted">Admin</span>
            </div>
          </div>
        </div>
        <div className="nav-profile-body">
          <Menu>
            {menuItem.map((el, i) => {
              return (
                <Menu.Item key={i}>
                  <a href={el.path}>
                    <Icon className="mr-3" type={el.icon} />
                    <span className="font-weight-normal">{el.title}</span>
                  </a>
                </Menu.Item>
              );
            })}
            <Menu.Item key={menuItem.legth + 1} onClick={Auth.logout}>
              <span>
                <LogoutOutlined className="mr-3" />
                <span className="font-weight-normal">Sign Out</span>
              </span>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    );
          };
  return (
    <div className="user-dropdown">
      <Dropdown overlay={userMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={imageUrl} />
        </Menu.Item>
      </Menu>
      </Dropdown>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { profileInfo } = state;
  return { profileInfo };
};
export default connect(mapStateToProps)(UserDropdown);