import React, { Component } from "react";
import { Link } from "react-router-dom";
import hamburger from "../../assets/images/icons/hamburger.svg";
import languageIcon from "../../assets/images/icons/language.svg";

import { Button, Dropdown, Select, Menu } from "antd";
import { Option } from "antd/lib/mentions";
import NotificationDropdown from "./notificationDropdown";
import UserDropdown from "./userDropdown";
class Header extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <header className="page-header">
        <div className="left-col">
          <button className="hamburger-btn" onClick={this.props.sideMenuToggle}>
            <img src={hamburger} alt="" />
          </button>
        </div>
        <div className="right-col">
          <div className="header-items">
            <UserDropdown />
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
