import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";

export const AppViews = ({ match }) => {
  console.log("match.url", match.url);
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${match.url}/dashboard`}
          component={lazy(() => import(`./dashboard`))}
        />
        <Route
          exact={true}
          path={`${match.url}/users/details/:id`}
          component={lazy(() => import(`./users/view/index`))}
        />
        <Route
          path={`${match.url}/users`}
          component={lazy(() => import(`./users`))}
        />
        <Route
          path={`${match.url}/user-roles`}
          component={lazy(() => import(`./user-roles`))}
        />
        {/* books */}
        <Route
          path={`${match.url}/books`}
          component={lazy(() => import(`./books`))}
        />
        {/* dailymanna */}
        <Route
          path={`${match.url}/dailymanna`}
          component={lazy(() => import(`./dailymanna`))}
        />
        {/* todayverse */}
         <Route
          path={`${match.url}/todayverse`}
          component={lazy(() => import(`./todayverse`))}
        />
        {/* inspiration */}
         <Route
          path={`${match.url}/inspiration`}
          component={lazy(() => import(`./inspiration`))}
        />
        {/* events */}
         <Route
          path={`${match.url}/events`}
          component={lazy(() => import(`./events`))}
        />
        {/* customerManagement */}
         <Route
          path={`${match.url}/customerManagement`}
          component={lazy(() => import(`./customerManagement`))}
        />
         {/* books */}
         <Route
          path={`${match.url}/banners`}
          component={lazy(() => import(`./carasouel`))}
        />
         <Route
          path={`${match.url}/setting/edit-profile`}
          component={lazy(() => import(`./pages/setting`))}
        />
        {/* sinceremilk */}
        <Route
          path={`${match.url}/sinceremilk`}
          component={lazy(() => import(`./sinceremilk`))}
        />
        {/* hed */}
        {/* sinceremilk */}
        <Route
          path={`${match.url}/hed`}
          component={lazy(() => import(`./hed`))}
        />
         <Route
          path={`${match.url}/devices`}
          component={lazy(() => import(`./devices`))}
        />
          <Route
          path={`${match.url}/broadcastgroups`}
          component={lazy(() => import(`./broadcastgroups`))}
        />
         <Route
          path={`${match.url}/broadcastmessages`}
          component={lazy(() => import(`./broadcastmessages`))}
        />
          <Route
          path={`${match.url}/transactions`}
          component={lazy(() => import(`./transactions`))}
        />
         <Route
          path={`${match.url}/donation`}
          component={lazy(() => import(`./donation`))}
        />
        <Redirect from={match.url} to={`${match.url}/dashboard`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
