import { combineReducers } from 'redux';
import { DEFAULT_STATE, DEFAULT_STATE_FF_EF } from '../../utils/Constants';
import Auth from './Auth';
import Theme from './Theme';
// import { locales } from '../../lang';

const createReducer = (reducerName, defaultStateParam) => {
  const defaultState = defaultStateParam || DEFAULT_STATE;
  return (state = defaultState, action) => {
    switch (action.type) {
      case `${reducerName}_PENDING`:
      case `${reducerName}_FULFILLED`:
      case `${reducerName}_REJECTED`:
        return Object.assign({}, action.payload);
      default:
        return state;
    }
  };
};

const createStaticReducer = (reducerName, defaultStateParam) => {
  const defaultState = defaultStateParam || DEFAULT_STATE;
  return (state = defaultState, action) => {
    switch (action.type) {
      case `${reducerName}`:
        return Object.assign({}, action.payload);
      default:
        return state;
    }
  };
};

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  login: createReducer('LOGIN', DEFAULT_STATE_FF_EF),
  forgotPasswordMail: createReducer('FORGOT_PASSWORD', DEFAULT_STATE_FF_EF),
  changepassword: createReducer('CHANGE_PASSWORD', DEFAULT_STATE_FF_EF),
  logout: createReducer('LOGOUT', { logout: false }),
  profileInfo: createReducer('PROFILE_INFO', DEFAULT_STATE_FF_EF),
  changeUserImage: createReducer('CHANGE_USER_IMAGE', DEFAULT_STATE_FF_EF),
  changeUserPassword: createReducer(
    'CHANGE_USER_PASSWORD',
    DEFAULT_STATE_FF_EF
  ),

  dashboardWidget: createReducer('DASHBOARD_WIDGET', DEFAULT_STATE_FF_EF),
  dashboardDevicesWidget: createReducer('DASHBOARD_DEVICES_WIDGET', DEFAULT_STATE_FF_EF), 
  dashboardSignupWidget:createReducer('DASHBOARD_SIGNUP_STATS', DEFAULT_STATE_FF_EF), 
  
  roles: createReducer('ROLES', DEFAULT_STATE_FF_EF),
  roleAdd: createReducer('ROLE_ADD', DEFAULT_STATE_FF_EF),
  roleById: createReducer('ROLE_BY_ID', DEFAULT_STATE_FF_EF),
  roleDelete: createReducer('ROLE_DELETE', DEFAULT_STATE_FF_EF),
  roleForDD: createReducer('GET_ROLES_DD', DEFAULT_STATE_FF_EF),

  users: createReducer('USERS', DEFAULT_STATE_FF_EF),
  userAdd: createReducer('USER_ADD', DEFAULT_STATE_FF_EF),
  userById: createReducer('USER_BY_ID', DEFAULT_STATE_FF_EF),
  userDelete: createReducer('USER_DELETE', DEFAULT_STATE_FF_EF),

  permissions: createReducer('PERMISSIONS', DEFAULT_STATE_FF_EF),
  userPermissions: createReducer('USER_PERMISSIONS', DEFAULT_STATE_FF_EF),

  books:createReducer('BOOKS', DEFAULT_STATE_FF_EF),
  booksVerse:createReducer('BOOKS_VERSES', DEFAULT_STATE_FF_EF),
  booksChapter:createReducer('BOOKS_CHAPTER', DEFAULT_STATE_FF_EF),

  dailyManna: createReducer('DAILY_MANNA', DEFAULT_STATE_FF_EF),
  dailyMannaAdd: createReducer('DAILY_MANNA_ADD', DEFAULT_STATE_FF_EF),
  dailyMannaById: createReducer('DAILY_MANNA_BY_ID', DEFAULT_STATE_FF_EF),
  dailyMannaDelete: createReducer('DAILY_MANNA_DELETE', DEFAULT_STATE_FF_EF),

  bulkUpload: createStaticReducer('BULK_UPLOAD', DEFAULT_STATE_FF_EF),
  updateBulkUpload:createReducer('UPDATE_BULK_UPLOAD', DEFAULT_STATE_FF_EF),

  languagesDD:createReducer('GET_LANGUAGES_DD', DEFAULT_STATE_FF_EF),
  
  bibleVerses:createReducer('BIBLE_VERSES', DEFAULT_STATE_FF_EF),
  bible:createReducer('BIBLE', DEFAULT_STATE_FF_EF),
  bibleBooks:createReducer('BIBLE_BOOKS', DEFAULT_STATE_FF_EF),

  todayVerse: createReducer('TODAY_VERSE', DEFAULT_STATE_FF_EF),
  todayVerseAdd: createReducer('TODAY_VERSE_ADD', DEFAULT_STATE_FF_EF),
  todayVerseById: createReducer('TODAY_VERSE_BY_ID', DEFAULT_STATE_FF_EF),
  todayVerseDelete: createReducer('TODAY_VERSE_DELETE', DEFAULT_STATE_FF_EF),

  inspiration: createReducer('INSPIRATION', DEFAULT_STATE_FF_EF),
  inspirationAdd: createReducer('INSPIRATION_ADD', DEFAULT_STATE_FF_EF),
  inspirationById: createReducer('INSPIRATION_BY_ID', DEFAULT_STATE_FF_EF),
  inspirationDelete: createReducer('INSPIRATION_DELETE', DEFAULT_STATE_FF_EF),

  events: createReducer('EVENTS', DEFAULT_STATE_FF_EF),
  eventsAdd: createReducer('EVENTS_ADD', DEFAULT_STATE_FF_EF),
  eventsById: createReducer('EVENTS_BY_ID', DEFAULT_STATE_FF_EF),
  eventsDelete: createReducer('EVENTS_DELETE', DEFAULT_STATE_FF_EF),

  // Customer Mangement
  customerList: createReducer('CUSTOMER_LIST', DEFAULT_STATE_FF_EF),
  customerById: createReducer('CUSTOMER_BY_ID', DEFAULT_STATE_FF_EF),
  userForDD: createReducer('GET_USER_DD', DEFAULT_STATE_FF_EF),

  verseBulkUpload: createStaticReducer('VERSE_BULK_UPLOAD', DEFAULT_STATE_FF_EF),
  verseUpdateBulkUpload:createReducer('VERSE_UPDATE_BULK_UPLOAD', DEFAULT_STATE_FF_EF),

  banner: createReducer('BANNER_IMAGE', DEFAULT_STATE_FF_EF),
  bannerAdd: createReducer('BANNER_IMAGE_ADD', DEFAULT_STATE_FF_EF),
  bannerById: createReducer('BANNER_IMAGE_BY_ID', DEFAULT_STATE_FF_EF),
  bannerDelete: createReducer('BANNER_IMAGE_DELETE', DEFAULT_STATE_FF_EF),

  bannerImagesOrder: createReducer('BANNER_ORDER', DEFAULT_STATE_FF_EF),

  //sinceremilk
  sincereMilk: createReducer('SINCERE_MILK', DEFAULT_STATE_FF_EF),
  sincereMilkAdd: createReducer('SINCERE_MILK_ADD', DEFAULT_STATE_FF_EF),
  sincereMilkById: createReducer('SINCERE_MILK_BY_ID', DEFAULT_STATE_FF_EF),
  sincereMilkDelete: createReducer('SINCERE_MILK_DELETE', DEFAULT_STATE_FF_EF),

  sincereMilkbulkUpload: createStaticReducer('SINCERE_MILK_BULK_UPLOAD', DEFAULT_STATE_FF_EF),
  sincereMilkupdateBulkUpload:createReducer('SINCERE_MILK_UPDATE_BULK_UPLOAD', DEFAULT_STATE_FF_EF),

    //hed
    hed: createReducer('HED', DEFAULT_STATE_FF_EF),
    hedAdd: createReducer('HED_ADD', DEFAULT_STATE_FF_EF),
    hedById: createReducer('HED_BY_ID', DEFAULT_STATE_FF_EF),
    hedDelete: createReducer('HED_DELETE', DEFAULT_STATE_FF_EF),
  
    hedBulkUpload: createStaticReducer('HED_BULK_UPLOAD', DEFAULT_STATE_FF_EF),
    hedUpdateBulkUpload:createReducer('HED_UPDATE_BULK_UPLOAD', DEFAULT_STATE_FF_EF),

    //devices
    device:createReducer('DEVICES', DEFAULT_STATE_FF_EF),

    //Broadcastgroups
  broadcastGroups: createReducer('BROADCAST_GROUPS', DEFAULT_STATE_FF_EF),
  broadcastGroupsAdd: createReducer('BROADCAST_GROUPS_ADD', DEFAULT_STATE_FF_EF),
  broadcastGroupsById: createReducer('BROADCAST_GROUPS_BY_ID', DEFAULT_STATE_FF_EF),
  broadcastGroupsDelete: createReducer('BROADCAST_GROUPS_DELETE', DEFAULT_STATE_FF_EF),
  buisnessGroupsDD:createReducer('GET_BUISNESS_GROUPS_DD',DEFAULT_STATE_FF_EF),

  //BROADCASTMESSAGES
  broadcastmessages: createReducer('BROADCAST_MESSAGE', DEFAULT_STATE_FF_EF),
  broadcastmessagesAdd: createReducer('BROADCAST_MESSAGE_ADD', DEFAULT_STATE_FF_EF),
  broadcastmessagesbyId: createReducer('BROADCAST_MESSAGE_BY_ID', DEFAULT_STATE_FF_EF),
  broadcastmessagesDelete: createReducer('BROADCAST_MESSAGE_DELETE', DEFAULT_STATE_FF_EF),
    //Broadscastmessage sent
    broadcastmessagesSend: createReducer('BROADCAST_MESSAGE_SEND', DEFAULT_STATE_FF_EF),

  transactions:createReducer('TRANSACTION_LIST', DEFAULT_STATE_FF_EF),
 });

export default reducers;
