import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppLayout from 'layout/app-layout';
import AuthLayout from 'layout/auth-layout';
import Auth from '../utils/Authorization';
import MainLayout from 'layout/main'

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { location } = props;
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/app" />
      </Route>
      <Route path="/auth">
        <AuthLayout />
      </Route>
      <RouteInterceptor path="/app" isAuthenticated={Auth.isLoggedIn()}>
        <MainLayout location={location} />
      </RouteInterceptor>
    </Switch>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale } = theme;
  const { token } = auth;
  return { locale, token };
};

export default withRouter(connect(mapStateToProps)(Views));
