import React, { useState } from "react";
import dashboardIcon from "../../assets/images/icons/dashboard.svg";
import manageAdminIcon from "../../assets/images/icons/manage-admin.svg";
import videoIcon from "../../assets/images/icons/videos.svg";
import productionIcon from "../../assets/images/icons/production.svg";
import manageCustomerIcon from "../../assets/images/icons/manage-customers.svg";
import monetizationIcon from "../../assets/images/icons/monetization.svg";
import billingIcon from "../../assets/images/icons/billing.svg";
import imageIcon from "../../assets/images/icons/image.svg";
import booksIcon from "../../assets/images/icons/books.svg";
import higherIcon from "../../assets/images/icons/higher.svg";
import notificationIcon from "../../assets/images/icons/notification.svg";
import analyticsIcon from "../../assets/images/icons/analytics.svg";
import settingsIcon from "../../assets/images/icons/settings.svg";
import playerIcon from "../../assets/images/icons/player.svg";
import supportIcon from "../../assets/images/icons/support.svg";
import liveTvIcon from "assets/images/icons/livetv.svg";
import adsIcon from "assets/images/icons/ads.svg";
import encodingIcon from "assets/images/icons/encoding.svg";
import productionsIcon from "assets/images/icons/productions.svg";
import homeIcon from "assets/images/icons/home.svg";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import miniLogo from "../../assets/images/mini-logo.svg";

const { SubMenu } = Menu;
const rootSubmenuKeys = [
  "videoMenu",
  "liveTvmenu",
  "layout",
  "admin",
  "subscription",
  "library",
  "managelayout",
  "encoding",
];
export default function Sidebar(props) {
  const [openKeys, setOpenKeys] = React.useState([]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
      console.log('keys', keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
 
  const [rolesState, setRolesState] = useState(() => {
    const permission = JSON.parse(
      window.localStorage.getItem('audetails')
    )?.permissions;
    const roleModule = {};
    permission.forEach((value) => {
      if (value.module in roleModule) return;
      roleModule[value.module] = true;
    });
    return Object.keys(roleModule || {});
  });

  const items = [
    {
      key: "Home",
      label: "Home",
      icon: homeIcon,
      path: '/app/dashboard',
      role: 'Dashboard'
    },
    {
      key: "customer",
      label: "Manage Customers",
      icon: manageCustomerIcon,
      subMenu: [
        {
          key: "Customers",
          label: "Customers",
          path: '/app/customerManagement',
          role: 'Customers'
        },
        {
          key: "Devices",
          label: "Devices",
          path: '/app/devices',
          role: 'Devices'
        },
      ]
    },
    {
      key: "books",
      label: "Books",
      icon:booksIcon ,
      path: '/app/books',
      role: 'Books'
    },
    {
      key: "dailymanna",
      label: "Daily Manna",
      icon: productionIcon,
      path: '/app/dailymanna',
      role: 'DailyManna'
    },
    {
      key: "sinceremilk",
      label: "Sincere Milk",
      icon: billingIcon,
      path: '/app/sinceremilk',
      role: 'SincereMilk'
    },
    {
      key: "hed",
      label: "Higher Everyday",
      icon: notificationIcon,
      path: '/app/hed',
      role: 'DailyManna'
    },
    {
      key: "todayverse",
      label: "Daily Verse",
      icon: productionsIcon,
      path: '/app/todayverse',
      role: 'DailyVerse'
    },
    {
      key: "inspiration",
      label: "Inspiration",
      icon: encodingIcon,
      path: '/app/inspiration',
      role: 'Inspiration'
    },
    {
      key: "events",
      label: "Events",
      icon: dashboardIcon,
      path: '/app/events',
      role: 'Events'
    },
    {
      key: "banners",
      label: "Banner Images",
      icon: imageIcon,
      path: '/app/banners',
      role: 'Banner'
    },
    // {
    //   key: "transactions",
    //   label: "Transactions",
    //   icon: monetizationIcon,
    //   path: '/app/transactions',
    //   role: 'Transactions'
    // },
    // {
    //   key: "donation",
    //   label: "donation",
    //   icon: monetizationIcon,
    //   path: '/app/donation',
    //   role: 'Donations'
    // },
    // {
    //   key: "accounting",
    //   label: "Accounting",
    //   icon: monetizationIcon,
    //   subMenu: [
    //     {
    //       key: "transactions",
    //       label: "Transactions",
    //       path: '/app/transactions',
    //       role: 'Transactions'
    //     },
    //     {
    //       key: "donation",
    //       label: "Donation",
    //       path: '/app/donation',
    //       role: 'Donations'
    //     },
    //   ]
    // },
    {
      key: "broadcasting",
      label: "Broadcasting",
      icon: analyticsIcon,
      subMenu: [
        {
          key: "broadcastgroups",
          label: "Broadcast Groups",
          path: '/app/broadcastgroups',
          role: 'Broadcasting Groups'
        },
        {
          key: "broadcastmessages",
          label: "Broadcast Messages",
          path: '/app/broadcastmessages',
          role: 'Broadcasting Messages'
        },
      ]
    },
    {
      key: "Admin",
      label: "Manage Admins",
      icon: manageAdminIcon,
      subMenu: [
        {
          key: "Admin",
          label: "Admin Users",
          path: '/app/users',
          role: 'Admin Users'
        },
        {
          key: "roles",
          label: "Admin Roles",
          path: '/app/user-roles',
          role: 'Admin Roles'
        },
      ]
    },
   
  ];
  return (
    <aside className="side-menu">
      <div className="logo">
        <img src={props.collapse ? miniLogo : logo} />
      </div>
      <div className="side-nav">
        <Menu
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          inlineCollapsed={!props.collapse}
        // selectedKeys={location.pathname}
        >
          {items.map((item) =>
            item?.subMenu?.filter((c) => rolesState.includes(c.role)).length > 0 ?
              (
                <SubMenu
                  key={item.key}
                  icon={<img src={item.icon} />}
                  title={item.label}
                >
                  {item?.subMenu.filter((c) => rolesState.includes(c.role)).map((c) =>
                    <Menu.Item key={c.key}>
                      <Link exact activeClassName="active" to={c.path}>
                        <span>{c.label}</span>
                      </Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )
              :
              (rolesState.includes(item.role))
              &&
              <Menu.Item key={item.key}>
                <Link exact to={item.path}>
                  <img src={item.icon} alt="" />
                  <span>{item.label}</span>
                </Link>
              </Menu.Item>
          )}

          {/* <Menu.Item key="/app/customerManagement">
              <NavLink exact activeClassName="active" to="/app/customerManagement">
              <img src={manageCustomerIcon} alt="" />
                <span>Customers</span>
              </NavLink>
            </Menu.Item>
          <Menu.Item key="/app/books">
            <NavLink exact to="/app/books">
              <img src={billingIcon} alt="" />
              <span>Books</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/app/dailymanna">
            <NavLink exact to="/app/dailymanna">
              <img src={productionIcon} alt="" />
              <span>Daily Manna</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/app/todayverse">
            <NavLink exact to="/app/todayverse">
              <img src={productionsIcon} alt="" />
              <span>Daily Verse </span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/app/inspiration">
            <NavLink exact to="/app/inspiration">
              <img src={encodingIcon} alt="" />
              <span>Inspiration</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/app/events">
            <NavLink exact to="/app/events">
              <img src={dashboardIcon} alt="" />
              <span>Events</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/app/banners">
            <NavLink exact to="/app/banners">
              <img src={videoIcon} alt="" />
              <span>Banner Images</span>
            </NavLink>
          </Menu.Item>
          <SubMenu
            key="admin"
            icon={<img src={manageAdminIcon} />}
            title="Manage Users"
          >
            <Menu.Item key="/app/users">
              <NavLink exact activeClassName="active" to="/app/users">
                <span>Admin Users</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/app/user-roles">
              <NavLink exact activeClassName="active" to="/app/user-roles">
                <span>Admin Roles</span>
              </NavLink>
            </Menu.Item> 
          </SubMenu> */}
          {/* <SubMenu
            key="contents"
            icon={<img src={manageCustomerIcon} />}
            title="Manage Contents"
          >
            <Menu.Item key="/app/imagecontents">
              <NavLink exact activeClassName="active" to="/app/imagecontents">
                <span>Image Contents</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/app/audiocontents">
              <NavLink exact activeClassName="active" to="/app/audiocontents">
                <span>Audio Contents</span>
              </NavLink>
            </Menu.Item>
          </SubMenu> */}
        </Menu>
      </div>
    </aside>
  );
}
