import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import Auth from '../../utils/Authorization';
import { connect } from 'react-redux';
import { getUserDetail } from './actions';
import { EditOutlined } from '@ant-design/icons';

const menuItem = [
  {
    title: 'Edit Profile',
    icon: EditOutlined,
    path: '/app/setting/edit-profile',
  },
  // {
  //   title: "Account Setting",
  //   icon: SettingOutlined,
  //   path: "/",
  // },
  // {
  //   title: "Billing",
  //   icon: ShopOutlined,
  //   path: "/",
  // },
  // {
  //   title: "Help Center",
  //   icon: QuestionCircleOutlined,
  //   path: "/",
  // },
];

export const NavProfile = (props) => {
  const { profileInfo, dispatch, ...restProps } = props;

  useEffect(() => {
    dispatch(getUserDetail());
  }, []);

  const [profileDetails, setProfileDetails] = useState([]);

  useEffect(() => {
    const {
      response: { data = [] },
      status,
      isFetching,
      isError,
    } = profileInfo;
    if (status >= 200 && status < 300) {
      setProfileDetails(data);
    }
  }, [profileInfo]);

  const profileImg =
    profileDetails.profileImage !== null ? profileDetails.profileImage : '';
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar
            src={profileImg}
            size={45}
            style={{ backgroundColor: '#87d068' }}
            icon={<UserOutlined />}
          />
          <div className="pl-3">
            <h4 className="mb-0">
              {profileDetails.firstName !== null
                ? profileDetails.firstName
                : ''}
              {profileDetails.lastName !== null ? profileDetails.lastName : ''}
            </h4>
            <span className="text-muted">{profileDetails.username}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.legth + 1} onClick={Auth.logout}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar
            style={{ backgroundColor: '#87d068' }}
            icon={<UserOutlined />}
          />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => {
  const { profileInfo } = state;
  return { profileInfo };
};
export default connect(mapStateToProps)(NavProfile);
