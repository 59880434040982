import {
  DashboardOutlined,
  ToolOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined,
  MonitorOutlined,
  ProjectOutlined,
} from '@ant-design/icons';

const dashBoardNavTree = [
  {
    key: 'dashboard',
    path: '/app/dashboard',
    title: 'dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const vodNavTree = [
  {
    key: 'vod',
    path: '/app/vod',
    title: 'VOD',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'cast',
        path: '/app/vod/cast',
        title: 'Cast',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'videos',
        path: '/app/vod/videos',
        title: 'Videos',
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const manageVideosNavTree = [
  {
    key: 'manageLiveTv',
    path: '/app/videos',
    title: 'manageLiveTv',
    icon: VideoCameraOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'livetv',
        path: '/app/livetv',
        title: 'livetv',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'epg',
        path: '/app/epg',
        title: 'epg',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'categories',
        path: '/app/categories',
        title: 'categories',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'genres',
        path: '/app/genres',
        title: 'genres',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'languages',
        path: '/app/languages',
        title: 'languages',
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const userConfigNavTree = [
  {
    key: 'manageUsers',
    path: '/app/users',
    title: 'manageUsers',
    icon: UsergroupAddOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'customers',
        path: '/app/customer',
        title: 'customers',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'users',
        path: '/app/users',
        title: 'users',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'user-roles',
        path: '/app/user-roles',
        title: 'user-roles',
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const monetizationConfigNavTree = [
  {
    key: 'monetization',
    path: '/app/monetization',
    title: 'monetization',
    icon: MonitorOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'subscriptions',
        path: '/app/subscriptions',
        title: 'subscriptions',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'ads',
        path: '/app/ads',
        title: 'ads',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'transactions',
        path: '/app/transactions',
        title: 'transactions',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'paymentGateway',
        path: '/app/paymentGateway',
        title: 'paymentGateway',
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const manageLayoutNavTree = [
  {
    key: 'manageLayout',
    path: '/app/static-pages',
    title: 'manageLayout',
    icon: ToolOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'liveTvLayout',
        path: '/app/livetv-layout',
        title: 'liveTvLayout',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'videoLayout',
        path: '/app/video-layout',
        title: 'Video',
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const analyticsConfigNavTree = [
  {
    key: 'analytics',
    path: '/app/settings',
    title: 'analytics',
    icon: ProjectOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'overview',
        path: '/app/analytics-overview',
        title: 'overview',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'engagement',
        path: '/app/analytics-engagement',
        title: 'engagement',
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const notificationConfigNavTree = [
  {
    key: 'notifications',
    path: '/app/notification',
    title: 'Notification',
    icon: ProjectOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'pushNotification',
        path: '/app/notification/push',
        title: 'Push Notification',
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  {
    // default theme structure
    key: 'dashboard',
    path: '/app/dashboard',
    title: 'dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      ...dashBoardNavTree,
      ...vodNavTree,
      ...manageVideosNavTree,
      ...userConfigNavTree,
      ...monetizationConfigNavTree,
      ...manageLayoutNavTree,
      // ...analyticsConfigNavTree,
      ...notificationConfigNavTree,
    ],
  },
];

export default navigationConfig;
